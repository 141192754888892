import React, { useEffect, useState, useRef } from 'react'
import classnames from 'classnames'

import './index.scss'

const Background = () => {
  const [skew, setSkew] = useState(0);
  const hasHero = useRef(false);
  const currentCase = useRef(null);
  const [caseStudyIn, setCaseStudyIn] = useState(false);

  useEffect(() => {
    const homeHeroImageProgressHandler = (event) => {
      if (hasHero.current) {
        setSkew(1 - event.detail.progress);
      }
    };

    const homeHeroImageLoadHandler = () => {
      hasHero.current = true;
      setSkew(1);
    }
    const homeHeroImageUnloadHandler = () => {
      hasHero.current = false;
      setSkew(0);
    }

    window.addEventListener('home-hero-image-progress', homeHeroImageProgressHandler);
    window.addEventListener('home-hero-load', homeHeroImageLoadHandler);
    window.addEventListener('home-hero-unload', homeHeroImageUnloadHandler);

    return () => {
      window.removeEventListener('home-hero-image-progress', homeHeroImageProgressHandler);
      window.removeEventListener('home-hero-load', homeHeroImageLoadHandler);
      window.removeEventListener('home-hero-unload', homeHeroImageUnloadHandler);
    };
  }, [setSkew])

  useEffect(() => {
    const caseStudyNextUpInView = (event) => {
      if (currentCase.current === event.detail.eventId) {
        setCaseStudyIn(event.detail.eventId);
        setCaseStudyIn(event.detail.inView);
      }
    }

    const caseStudyNextUpLoad = (event) => {
      currentCase.current = event.detail.eventId;
    }

    const caseStudyNextUpUnload = (event) => {
      if (currentCase.current === event.detail.eventId) {
        currentCase.current = null;
      }
    }

    window.addEventListener('case-study-next-up-in-view', caseStudyNextUpInView);
    window.addEventListener('case-study-next-up-load', caseStudyNextUpLoad);
    window.addEventListener('case-study-next-up-unload', caseStudyNextUpUnload);

    return () => {
      window.removeEventListener('case-study-next-up-in-view', caseStudyNextUpInView);
      window.removeEventListener('case-study-next-up-load', caseStudyNextUpLoad);
      window.removeEventListener('case-study-next-up-unload', caseStudyNextUpUnload);
    };
  }, [setCaseStudyIn])

  return (
    <div
      className={classnames('background', {'is-case-study-next-up-in': caseStudyIn})}
      style={{'--background-line-skew': skew}}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default Background
