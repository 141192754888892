import React, { useEffect, useRef, useState } from 'react'
import Link from '~components/Link'
import classnames from 'classnames'

import './index.scss'
import useMediaQuery from '~hooks/useMediaQuery'

const Header = ({ isPreview }) => {
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useMediaQuery('(max-width: 768px)')
  const menuRef = useRef()
  const headerRef = useRef()
  const [lastScrollPosition, setLastScrollPosition] = useState(0)
  const [isScrolled, setIsScrolled] = useState(false)

  const clickHandler = () => {
    if (isMobile) {
      setIsOpen(!isOpen)
    }

    if (!isOpen && isMobile) {
      headerRef.current.classList.add('is-open')
    } else {
      headerRef.current.classList.remove('is-open')
    }
  }

  useEffect(() => {
    if (!isMobile && isOpen) {
      headerRef.current.classList.remove('is-open')
      setIsOpen(false)
    }

    /* remove shy header per clients request, but keeping the code
    const scrollHandler = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (!isScrolled) {
        setIsScrolled(isScrolled => !isScrolled)
      }

      if (headerRef.current) {
        if (isMobile && isOpen) {
          return
        } else {
            if (isScrolled) {
              headerRef.current.classList.toggle('is-hidden', lastScrollPosition < scrollTop)
            } else {
              return
            }
        }
      }
      setLastScrollPosition(scrollTop)
    }

    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
    */
  }, [isOpen, isMobile, lastScrollPosition, isScrolled])

  const headerClass = classnames(
    'header'
  )

  const Menu = (
    <div className="header__nav__menu">
      <ul className="header__nav__menu__list" aria-hidden={isOpen ? false : true}>
        <li className="header__nav__menu__list__item">
          <Link to="/work" linkClass="header__nav__menu__list__link" isPreview={isPreview}
            activeClassName="header__nav__menu__list__link--active">Work</Link>
        </li>
        <li className="header__nav__menu__list__item">
          <Link to="/about" linkClass="header__nav__menu__list__link" isPreview={isPreview}
            activeClassName="header__nav__menu__list__link--active">About</Link>
        </li>
        <li className="header__nav__menu__list__item">
          <Link to="/contact" linkClass="header__nav__menu__list__link" isPreview={isPreview}
            activeClassName="header__nav__menu__list__link--active">Contact</Link>
        </li>
        <li className="header__nav__menu__list__item">
          <Link to="/latest" linkClass="header__nav__menu__list__link" isPreview={isPreview}
            activeClassName="header__nav__menu__list__link--active">Latest</Link>
        </li>
      </ul>

      <div className="header__nav__menu__footer">
        <div className="header__nav__menu__footer__content">
          <div className="header__nav__menu__footer__content--left">
            <Link linkClass="header__nav__menu__footer__privacy-terms" to="/privacy-terms"
              isPreview={isPreview}>Privacy &amp; Terms</Link>
          </div>
          <div className="header__nav__menu__footer__content--right">
            <nav className="header__nav__menu__footer__nav">
              <ul className="header__nav__menu__footer__nav-list">
                <li className="header__nav__menu__footer__nav__item">
                  <Link to="/careers" isPreview={isPreview}>Careers</Link>
                </li>
                <li className="header__nav__menu__footer__nav__item">
                  <Link to="/huge-ignites" isPreview={isPreview}>Huge Ignites</Link>
                </li>
              </ul>
            </nav>
            <nav className="header__nav__menu__footer__nav header__nav__menu__footer__nav--socials">
              <ul className="header__nav__menu__footer__nav-list">
                <li className="header__nav__menu__footer__nav__item">
                  <a href="https://www.instagram.com/hugedesignsf/" target="_blank" rel="noopener noreferrer">Instagram</a>
                </li>
                <li className="header__nav__menu__footer__nav__item">
                  <a href="https://www.linkedin.com/company/huge-design/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </li>
                <li className="header__nav__menu__footer__nav__item">
                  <a href="https://twitter.com/HugeDesignSF" target="_blank" rel="noopener noreferrer">Twitter</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <header className={headerClass} ref={headerRef}>
        <div className="header__container">
          <Link to="/" linkClass="header__nav__logo" isPreview={isPreview}>
            <svg className="header__nav__logo__svg" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg">
              <clipPath id="a"><path d="m0 0h103v103h-103z"/></clipPath>
              <g clip-path="url(#a)"><path d="m0 0h103v103h-103z" fill="#fff"/><path clip-rule="evenodd" d="m0 102.999h103v-102.999h-103zm69.409-38.795h-14.456v-25.408h23.021v-6.91h-29.93v16.16h-23.02v-16.16h-6.908v39.228h6.909v-16.159h23.02v16.159h29.927v-6.91h-8.565zm8.565 0h6.91v-25.41l-6.91.002z" fill="#000" fill-rule="evenodd"/></g>
            </svg>
          </Link>
          <nav className="header__nav" name="primary">
            <div linkClass="header__nav__logo--spacer"/>
            <button className="header__nav__toggler" aria-expanded={isOpen} onClick={clickHandler}>
              <span className="header__nav__toggler__bars">
                <span></span>
                <span></span>
                <span></span>
              </span>
              <span className="header__nav__toggler__cross">
                <span></span>
                <span></span>
              </span>
            </button>
            {!isMobile && Menu}
          </nav>
          {isMobile && Menu}
        </div>
      </header>
    </>
  )
}

export default Header
