import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'

const Link = ({ linkClass, activeClassName, to, children, isPreview }) => {
  const exit = {
    length: 0.5,
  }

  const entry = {
    length: 0.5,
    appearAfter: 0.5,
    state: { type: 'default' }
  }

  if (isPreview) {
    return (
      <div className={linkClass}>
        {children}
      </div>
    )
  }

  return (
    <TransitionLink className={linkClass} activeClassName={activeClassName} to={to} exit={exit} entry={entry}>
      {children}
    </TransitionLink>
  )
}

export default Link
