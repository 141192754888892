import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import { TransitionState } from 'gatsby-plugin-transition-link'
import classnames from 'classnames'
import '../../styles/index.scss'

import settings from '../../content/website-settings.yaml'

import Background from '~components/Background'

const TemplateWrapper = ({ children }) => {
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{settings.site_title}</title>
        <meta name="description" content={settings.site_description} />

        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}static/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}static/favicon-16x16.png`}
          sizes="16x16"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={settings.site_title} />
        <meta property="og:url" content="/" />
        <meta property="og:description" content={settings.site_description} />
        <meta
          property="og:image"
          content={`${withPrefix('/')}static/og-image.png`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <TransitionState>
        {(data) => {
          // this fallback is needed for CMS preview
          const transitionStatus = data && data.entry.state.type === 'default' ? data.transitionStatus : 'entered';
          const mainClass = classnames(
            'main',
            {
              'is-hidden': transitionStatus === 'entering' || transitionStatus === 'exiting'
            },
          )
          return (
            <main className={mainClass} style={{'--color-accent': `#${settings.accent_color}`}}>
              <Background />
              <div className="main-content">{children}</div>
            </main>
          )
        }}
      </TransitionState>
    </div>
  )
}

export default TemplateWrapper
